<template>
  <div >
    <div class="mapLeft" :style="{width: fullState? '0' : '300px'}">
      <div class="Putaway"  @click="Putaway()" :style="{right: fullState? '3px' : '290px'}">
        <img src="@/assets/btn.png" :style="{transform:fullState? 'rotate(180deg)': 'rotate(0deg)'}">
      </div>
      <el-form ref="searchForm" class="searchForm"  label-width="80px" style="margin: 15px 15px 0 5px;">
        <el-form-item  label="压力级别" >
          <el-select  multiple clearable @change="getList(1)" v-model="pageParamsLine.stressLevel" collapse-tags style="width: 100%;">
            <el-option
                v-for="item in pressuerType"
                :key="item.dictCode"
                :label="item.name"
                :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="材质规格" >
            <el-cascader
             style="width: 100%;"
            :options="texture"
            :show-all-levels="false"
            :props="{ multiple: true,value: 'id', label: 'name',}"
            clearable
            collapse-tags
            v-model="materialList"
            @change="SearchLineChange"></el-cascader>
        </el-form-item>
        <el-form-item  label="状 态" >
          <el-select multiple clearable @change="getList(1)" v-model="pageParamsLine.useStatus" collapse-tags style="width: 100%;">
            <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.dictCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="buttonBox">
          <el-button size="small" type="primary" 
          v-if="$anthButtons.getButtonAuth('addLine')"  
          class="searchBtn" @click="addPoint" style="width:117px">新增巡检管线</el-button>
          <el-button @click="replacement" 
          v-if="$anthButtons.getButtonAuth('resetLine')"  
           size="small" style="width:117px">重置</el-button>
           <el-button @click="backtrack" 
          v-if="tbBasicName == 'seed'"  
           size="small" style="width:117px">返回</el-button>
        </div>  
      </el-form>
        <div style="background:#ffffff">
          <div class="topbt">
            <el-radio-group v-model="radio1" @change="system">
              <el-radio-button label="1">本系统管线</el-radio-button>
              <el-radio-button label="2">GIS管线</el-radio-button>
            </el-radio-group>
          </div>
            <div style="margin: 0px 0;height:36px;background:#F5F5F5;line-height:36px">
              <span style="margin: 0 10px">管线数量：{{totalLineNum}}个</span>
              <span >总长度：{{(sumLengthNum/1000).toFixed(3)}}km</span>
            </div>
            <template v-if="tbBasicName == 'parent' && tableDataLine.length>0">
           <div :class="item.state ? 'pitchOn box-card lineBox' : 'box-card lineBox'" v-for="(item,index) in tableDataLine" :key="index">
             <div class="examine" @click="parenteditFun(item)">
              <p class="lineP">管线名称：<span >{{item.pipeName}}</span></p>
              <p class="lineP">管线长度：<span >{{(item.pipeLength || 0).toFixed(2)}}m</span></p>
              <p class="lineP">公称直径：<span >{{item.materialName || item.diameterName}}</span></p>
              <p class="lineP">压力级别：<span >{{ item.pressureLevelName}}</span></p>
              </div>
              <el-divider></el-divider>
           </div>
            </template>
            <template v-if="tbBasicName == 'seed' && tableDataLine.length>0">
           <div :class="item.state ? 'pitchOn box-card lineBox' : 'box-card lineBox'" v-for="(item,index) in tableDataLine" :key="index">
             <div class="examine" @click="editFun(item)">
              <p class="lineP">管线名称：<span >{{item.pipeName}}</span></p>
              <p class="lineP">管线长度：<span >{{(item.pipeLength || 0).toFixed(2)}}m</span></p>
              <p class="lineP">公称直径：<span >{{item.materialName || item.diameterName}}</span></p>
              <p class="lineP">压力级别：<span >{{ item.pressureLevelName}}</span></p>
              </div>
              <el-divider></el-divider>
           </div>
            </template>
            <template v-if="!tbBasicName && tableDataLine.length>0">
              <div :class="item.state ? 'pitchOn box-card lineBox' : 'box-card lineBox'" v-for="(item,index) in tableDataLine" :key="index">
                <div class="examine" @click="editFun(item)">
                  <p class="lineP">管线名称：<span >{{item.lineName}}</span></p>
                  <p class="lineP">管线长度：<span >{{(item.length || 0).toFixed(2)}}m</span></p>
                  <p class="lineP">公称直径：<span >{{item.diameterName}}</span></p>
                  <p class="lineP">压力级别：<span >{{ startName(item.stressLevel)}}</span></p>
                </div>
                  <el-divider></el-divider>
              </div>
            </template>
            <!-- <template v-else>
           <div :class="item.state ? 'pitchOn box-card lineBox' : 'box-card lineBox'" v-for="(item,index) in tableDataLine" :key="index">
             <div class="examine" @click="editFun(item)">
              <p class="lineP">管线名称：<span >{{item.layerName}}</span></p>
              <p class="lineP">管线长度：<span >{{(item.pipeLength || item.length).toFixed(2)}}m</span></p>
              <p class="lineP">管材型号：<span >{{item.materialName || '--'}}</span></p>
              <p class="lineP">压力级别：<span >{{ item.pressureLevelName}}</span></p>
              </div>
              <el-divider></el-divider>
           </div>
            </template> -->
            <div class="pageBox">
              <el-pagination
                  small
                :current-page="pageParamsLine.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParamsLine.size"
                layout="prev, pager, next"
                :total="totalLine"
                :page-count="pagerCount"
                @size-change="handleSizeChangeLine"
                @current-change="handleCurrentChangeLine"
              >
              </el-pagination>
            </div>
        </div>
    </div>
    <div class="mapRight" ref="mapRightRef" id="mapRightId">
          <subMap ref="subMap" :modeal="'1'" :layer-name="layerName" :layers="layers" :layer-code="layerCode" @setLocation="setLocation"/>  
          <LineForm @getListArea="SearchLineDataChange" :showForm='showForm' v-if="showForm" @resetData="resetData" ref="formBox"  @save="save" @closePDraw="closePDraw" :addForm='addForm'/>
    </div>
  </div>
</template>

<script>

import subMap from '@/views/mapTool/subMapLine'
import LineForm from '@/views/linegasMap/commonForm/LineForm'
import { patrolGetDict,findDictTreeLine } from "@/RequestPort/maintenance";
import {serverMap} from "@/views/Gis/utils/dict"
import { getNetwork,getLinePage,lineDelete,GetNetworkDetail,selectDictListByParentCode,lineGet,pageStatisticsStandingBook,pipePageStandingBook } from '@/apis/commonType'

export default {
  name: 'GisMap',
  components: {subMap ,LineForm},

  data() {
    return {
      name: '管线',
      pagerCount:3,
      // 管线新增编辑参数
      addForm:{
        lineName:'',
        useStatus:'',
        gasType:'',
        material:'',
        stressLevel:'',
        thickness:'',
        diameter:'',
        buriedType:'',
        styleColor:'#0062FF',
        location:'',
        networkId:'',
        length:0,
        burialDepth:'',
        bore:'',
        buildDate:'',
        through:'',
      },
      // 切系统
      radio1:1,
      layerName: 'patrol_lines',
      layerCode: '',
      // 管理列表参数
      pageParams: {
        current: 1,
        size: 100
      },
      // 详细列表参数
      pageParamsLine: {
        current: 1,
        size: 100,
        buildDate:'',
        bore:'',
        buriedType:'',
        networkId:'',
        useStatus:[],
        stressLevel:[],
        diameter:[],
        pipeName:''
      },
      // 详细总条数
      totalLine: 0,
      // sumLength:'',
      // 管线数量
      totalLineNum:0,
      // 管线总长度
      sumLengthNum :'',
      // 管理总条数
      total: 0,
      // areaData:[],
      // 管理列表内容
      tableData:[],
      // 详细列表内容
      tableDataLine:[],  
      // 展示管理页面还是详细页面    
      // tabActive:1,
      showForm:false,
      // NetWorkDetail:{},
      // AllLineNum:0,
      layers:"'patrol_lines'",
      // ,'patrol_networks_jihe'
      // searchLineForm:{
      //   buildDate:'',
      //   bore:'',
      materialList:[],
      // },
      // 建设年度
      yearArray:[],
      // 获取当前年分
      nowYear:'',
      // buryMode:[
      //   '地下',
      //   '架空',
      //   '穿越',
      // ],
      // 状态
      statusList:[],
      // 材质
      texture:[],
      // 压力级别
      pressuerType:[],
      searchTo:0,
      // 管线管理弹窗的展示隐藏
      fullState:false,
      // 显示编辑
      // compile:false,
      systemName:'',
      tbBasicName:'parent',
    }
  },
  computed: {},
  destroyed () {
    this.$eventBus.$off('vectoradd') 
   },
  mounted() {
    this.$eventBus.$on('vectoradd', data => { 
      this.showForm = false
      this.addForm={
        lineName:'',
        useStatus:'',
        gasType:'',
        material:'',
        stressLevel:'',
        thickness:'',
        diameter:'',
        buriedType:'',
        styleColor:'#0062FF',
        location:'',
        networkId:'',
        length:0,
        burialDepth:'',
        bore:'',
        buildDate:'',
        through:'',
      }
    })
    //  获取管理列表
    // this.getListArea()
    this.SearchLineDataChange('',1)
    // 获取管线个数和总长度
    // lineGet({...this.pageParamsLine}).then((e)=>{
    //  this.totalLineNum = e.data.count;
    //  this.sumLengthNum = e.data.length;
    // })
    this.$eventBus.$emit('pageStart', 'line' )
    // 获取建设年度
    this.nowYear = new Date().getFullYear()
 //   this.yearArrayFun()
    //获取压力级别
    // selectDictListByParentCode({parentCode:512}).then((e)=>{
    //   this.pressuerType = e.data
    // })
    // 压力级别
    this.pressuerType = serverMap[1006]
    // 管线状态
      this.statusList = serverMap[1007]
    // 获取材质
    // patrolGetDict({pid:'524,518,70,46,558'}).then(res => {
    //     if(res.code == 200) {
    //       res.data[0].children.forEach(el => {
    //         if(el.id == 518) {
    //           this.texture =el.children
    //         }
    //       })
    //       // this.texture = res.data[0].children[0].children
    //     } else {
    //       this.$message.error(res.msg)
    //     }
    //   })
      // 所有字典
    findDictTreeLine({dictCode:'100023,100024,100028,100029,100004,30011'}).then(res => {
      // 管材
      this.texture = res.data['100024']
    })
   // this.$store.commit("layersChange", 'noQuery');
  },
  methods: {
    startName(val){
      for(let i=0;i<this.pressuerType.length;i++){
        if(this.pressuerType[i].dictCode == val) {
          return this.pressuerType[i].name
        }
      }
    },
    /**
      * 重置
     */
    replacement(){
      this.$eventBus.$emit("RemoveArea");
      this.pageParamsLine= {
        current: 1,
        size: 100,
        buildDate:'',
        bore:'',
        buriedType:'',
        networkId:'',
        useStatus:[],
        stressLevel:[],
        diameter:[],
        pipeName:''
      }
      this.materialList=[]
      this.tbBasicName = 'parent'
      // this.tabActive = 1
      // this.pageParamsLine = {
      //   current: 1,
      //   size: 100,
      // }
      // 更新管理列表
      // this.getListArea()
      this.SearchLineDataChange()
      // 获取管线数量和总长度
      // lineGet(this.pageParams ).then((e)=>{
      //   this.totalLineNum = e.data.count;
      //   this.sumLengthNum = e.data.length;
      // })
      // 关闭弹窗
      this.closePDraw()
    },
    /**
     * 点击收回（右侧菜单展示）
     */
    Putaway() {
      this.fullState = !this.fullState
      // this.$store.commit('bottom/FullStateChange', !state)
    },
    /**
     * 级联菜单选择
     */
    SearchLineChange(val){
      let materialList = []
      val.forEach(el => {
            materialList.push(el[1])
      })
      this.pageParamsLine.diameter = materialList
      // 刷新列表
      this.getList()
      
    },
    /**
     * 添加管线（取消清空数据列表）
     */
    resetData(){
      this.addForm = {
        lineName:'',
        useStatus:'',
        gasType:'',
        material:'',
        stressLevel:'',
        diameter:'',
        buriedType:'',
        styleColor:'#0062FF',
        location:'',
        thickness:'',
        networkId:'',
        length:0,
        burialDepth:'',
        bore:'',
        buildDate:'',
        through:'',
      }
    },
    /**
     * 选择那个系统的管线
     */
    system(val){
      // if(val == 2) {
      //   this.tbBasicName = 'parent'
      // }
      this.$eventBus.$emit("RemoveArea");
      // this.pageParamsLine.current = 1
      // this.SearchLineDataChange()
      this.replacement()
    },
    /**
     * 查询列表（详细列表数据）
     */
    SearchLineDataChange(val,type){
      let src = ''
      let typesrc = ''
      let typesrces = ''
      let condition =""
      let sql = ""
      let useStatus = ""
      let useStatuses = ""
      console.log(this.tbBasicName)
      // 压力级别
      if(this.pageParamsLine.stressLevel.length > 0) {
        let index = 0
         this.pageParamsLine.stressLevel.forEach(el => {
           index+=1
           if(this.pageParamsLine.stressLevel.length <= index){
             src += "'"+el+"'"
           } else {
             src += "'"+el+"'"+","
           }
        } )
        condition = 'stress_level in('+src+')'
        sql =  condition
      }
      // 材质规格
      if(this.pageParamsLine.diameter.length > 0) {
        let index = 0
         this.pageParamsLine.diameter.forEach(item => {
           index+=1
           if(this.pageParamsLine.diameter.length <= index){
             typesrc += "'"+item+"'"
           } else {
             typesrc += "'"+item+"'"+","
           }
        } )
        typesrces = 'diameter in ('+ typesrc +')'
        sql =  typesrces
      }
       // 状态
      if(this.pageParamsLine.useStatus.length > 0) {
        let index = 0
         this.pageParamsLine.useStatus.forEach(el => {
           index+=1
           if(this.pageParamsLine.useStatus.length <= index){
             useStatus += el
           } else {
             useStatus += el+","
           }
        } )
        useStatuses = 'use_status in('+useStatus+')'
        sql =  useStatuses
      }
      // 压力级别和材质规格
      if(this.pageParamsLine.stressLevel.length > 0 && this.pageParamsLine.diameter.length> 0) {
        sql =  condition +' and '+ typesrces 
      }
      // 压力级别和状态
      if(this.pageParamsLine.stressLevel.length > 0 && this.pageParamsLine.useStatus.length) {
        sql =  condition +'and '+ useStatuses 
      }
      //材质规格和状态
      if(this.pageParamsLine.useStatus.length > 0 && this.pageParamsLine.diameter.length) {
        sql =  typesrces +' and '+ useStatuses 
      } 
      // 压力级别和材质规格和状态
      if(this.pageParamsLine.stressLevel.length > 0 &&this.pageParamsLine.diameter.length > 0 && this.pageParamsLine.useStatus.length>0){
        sql =  condition +' and '+ typesrces +'and '+ useStatuses 
      }
      // 啥条件没有
      if(this.pageParamsLine.stressLevel.length == 0 &&this.pageParamsLine.diameter.length == 0 && this.pageParamsLine.useStatus.length == 0){
      sql = "1=1 "
      }else{
        sql = '('+sql + " and type='line') or type='point' or type='network' or type='envent' or type='user'"
      }
     
      if  (type!=1){
        this.$eventBus.$emit("wmlineWalking", {layer:'linqing:lineWalking',sql});
      }
      
      //  this.compile = val == 1 ? false : true
      let p = {
        ...this.pageParamsLine,
      }
      p.stressLevel = p.stressLevel+''
      p.diameter = p.diameter+''
      p.useStatus = p.useStatus+''
      if(this.tableDataLine.length == 1 && (this.pageParamsLine.current != 1)) {
        p.current -= 1 
      }
      // 本系统
      if(this.radio1 == 1) {
        this.tbBasicName = ''
        p.tableName = 'patrol_lines'
        // 详细列表数据
        getLinePage(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
        // 获取管线数量和总长度
        p.lineType = 'length'
        lineGet(p).then((e)=>{
          this.totalLineNum = e.data.totalLineNum;
          this.sumLengthNum = e.data.sumLengthNum;
        })
      } else if(this.radio1 == 2 && this.tbBasicName == 'parent') {
       
         p.tableName = 'tb_basic_pipe'
         // 详细列表数据
         p.lineType = 'length'
        pageStatisticsStandingBook(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
         // 获取管线数量和总长度
        lineGet(p).then((e)=>{
          this.totalLineNum = e.data.totalLineNum;
          this.sumLengthNum = e.data.sumLengthNum;
        })
        // this.totalLine = 0
        //   this.tableDataLine =[]
      } else if(this.radio1 == 2 && this.tbBasicName == 'seed') {
       
         p.tableName = 'tb_basic_pipe'
         // 详细列表数据
         p.lineType = 'length'
        pipePageStandingBook(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
         // 获取管线数量和总长度
        lineGet(p).then((e)=>{
          this.totalLineNum = e.data.totalLineNum;
          this.sumLengthNum = e.data.sumLengthNum;
        })
        // this.totalLine = 0
        //   this.tableDataLine =[]
      }
      else {
        
         p.tableName = 'tb_basic_pipe'
         // 详细列表数据
         p.lineType = 'length'
        getLinePage(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
         // 获取管线数量和总长度
        lineGet(p).then((e)=>{
          this.totalLineNum = e.data.totalLineNum;
          this.sumLengthNum = e.data.sumLengthNum;
        })
        // this.totalLine = 0
        //   this.tableDataLine =[]
      }
    },
    /**
     * 建设年份只能获取2007以上的
     */
    // yearArrayFun(){
    //   if(this.nowYear >= 2016){
    //       this.yearArray.push(this.nowYear)
    //       this.nowYear --
    //       this.yearArrayFun()
    //   }else{
    //     return
    //   }
    // },
    reloadList(){
      //  this.getList2()
      this.SearchLineDataChange()
    },
    backtrack(){
      if( this.tbBasicName = 'seed') {
        this.tbBasicName = 'parent'
        this.pageParamsLine.current = 1
        if(this.pageParamsLine.pipeName) delete this.pageParamsLine.pipeName
        let p = {
          ...this.pageParamsLine,
        }
        p.tableName = 'tb_basic_pipe'
        pageStatisticsStandingBook(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
        
      }
      // 关闭弹窗
      this.$eventBus.$emit("RemoveArea");
      // this.closePDraw()
    },
    // 查子线
    parenteditFun(item){
      
      this.tbBasicName = 'seed'
      this.pageParamsLine.current = 1
      this.pageParamsLine.pipeName = item.pipeName
         let p = {
          ...this.pageParamsLine,
        }
        pipePageStandingBook(p).then(res => {
          this.totalLine = res.data.total
          this.tableDataLine = res.data.records
          // this.tabActive = 2
          this.searchTo = 1
        })
    },
    /**
     * 编辑管线
     */
    editFun(item){
      let loctions 
      if(!this.tbBasicName) {
        loctions = this.$refs.subMap.formatLocation(item.location,'LineString')
      } else {
        loctions = this.$refs.subMap.formatLocation(item.line,'LineString')
        item.tableName = 'tb_basic_pipe'
      }
       
      this.$refs.subMap.loctiones(loctions)
      this.$eventBus.$emit('vectorEdit',{type:''} )
      this.tableDataLine.forEach(el => {
        if(el.id == item.id){
          el.state = true
        } else {
          el.state = false
        }
      })
      this.$forceUpdate()
      this.$eventBus.$emit('xfSwitch',true)
      // this.closePDraw()
      let lnglat
      if(!this.tbBasicName) {
        lnglat = new AMap.LngLat(item.coordinate[0][0], item.coordinate[0][1])
      } else {
        lnglat = new AMap.LngLat(item.lineArray[0][0], item.lineArray[0][1])
      }
      let data={
        lnglat,
        data:item,
        type:'view_all_line',
        start:'linqing:view_all_line'
      }
      this.$eventBus.$emit('patrolLinesfun', data )
      // 打开编辑弹窗
      // this.$eventBus.$emit('vectorEdit', data )
    },
    /**
     * 删除（详细列表）
     */
     delFun(id){
      this.$confirm(`确定要删除吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {       
            let p = {
                id: id 
              }
              lineDelete(p).then(res => {
                if(res.code==200){
                  this.$eventBus.$emit('reloadLayer')
                  this.$message.success(res.msg)
                  this.SearchLineDataChange()
                  // this.getList2()
                  // this.GetNetworkDetailFun()
                }                
              })
      })
       
    },
    /**
     * 添加管线
     */
    addPoint(){
      this.$message.success('请先在地图上点选位置，然后填写左侧表单')
      this.$eventBus.$emit('closeEdit')
      this.$eventBus.$emit('xfSwitch',true)
      this.$refs.subMap.getLocation('line')
    },
    getMapDistanceApi(position1,position2){
      var lng1 = position1[0]
      var lat1 = position1[1]
      var lng2 = position2[0]
      var lat2 = position2[1]
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;
      s = Math.round(s * 10000) / 10000;
      s = s * 1000
      return s;
    },
    setLocation(point){
      this.resetData()
      this.addForm.locations=point
      let allLength = 0
      point.forEach((item,index)=>{
        if(index < point.length-1){
          allLength += this.getMapDistanceApi([item.lng,item.lat],[point[index+1].lng,point[index+1].lat])
        }
      })
      this.addForm.length = allLength.toFixed(2)
      this.showForm=true
      // this.$eventBus.$emit('showFormShow', true )
      let data={
        data:{coordinate:point},
        type:'view_all_line'
      }
      this.$eventBus.$emit('vectorEdit', data )
      this.$refs.subMap.clearDraw()

    },
    /**
     * 取消（关闭弹窗）
     */
    closePDraw(){
      if(this.$refs.formBox) {
     this.$refs.formBox.resetFields()
      }
     this.$refs.subMap.clearLocation()
     this.$eventBus.$emit('closeEdit')
     this.showForm = false
     this.$eventBus.$emit('showFormShow', false )
     this.$eventBus.$emit('xfSwitch',false)
     this.resetData()
    },
   
    save(){
          // this.getListArea()
          // this.getList2()
          this.closePDraw()
          this.getList()
         // this.$eventBus.$emit('reloadLayer')
          this.$eventBus.$emit('closeEdit')
          this.$eventBus.$emit('xfSwitch',false)
    },
    /**
     * 查询
     */
    getList(){
      this.tbBasicName = ''
      this.$eventBus.$emit("RemoveArea");
      this.pageParamsLine.current = 1
      this.SearchLineDataChange()
    },
    /**
     * 获取详细列表(废弃)
     */
    // getList2() {
    //   let p = {
    //     ...this.pageParamsLine,
    //   }
    //   // 获取列表数据
    //   getLinePage(p).then(res => {
    //     this.totalLine = res.data.total
    //     this.tableDataLine = res.data.records
    //   })
    //   // 获取数量和总长度
    //   lineGet(p).then((e)=>{
    //     this.totalLineNum = e.data.count;
    //     this.sumLengthNum = e.data.length;
    //   })
    // },
    /**
     * 查询列表（管理列表）
     */
    // getListArea() {
    //   let p = {
    //     ...this.pageParams,
    //     ...this.searchForm
    //   }
    //   getNetwork(p).then(res => {
    //     this.total = res.data.total
    //     this.tableData = res.data.records
    //   })
    // },
    // handleSizeChange(val) {
    //   this.pageParams.size = val
    //   this.getListArea()
    // },
    // handleCurrentChange(val) {
    //   this.pageParams.current = val
    //   this.getListArea()
    // },
    /**
     * 列表下面的分页
     */
    handleSizeChangeLine(val) {
      this.pageParamsLine.size = val
      this.SearchLineDataChange()
    },
    handleCurrentChangeLine(val) {
      this.pageParamsLine.current = val
      this.SearchLineDataChange()
    },
  }
}
</script>

<style lang="less" scoped>
@media screen and ( max-width: 1024px ) {
  
}
// 区域鼠标移上事件
.examine{
 cursor: pointer;
}
.mapLeft {
  height: calc(100vh - 150px);
  width: 350px;
  background: #fff;float:right;overflow:auto;
  .searchForm{
    // label内容
    /deep/.el-form-item__label{
      color: #0c235b;
    }
    // 搜索input
    /deep/.el-input__inner{
      height: 36px!important;
    }
    /deep/.el-form-item {
    margin-bottom: 12px;
}
  }
  
}
.topbt{text-align: right;;margin-top:10px;}
.mapRight{
  width: auto;
  margin-left:0;position: relative;overflow: hidden;
  height:100%;

}
.pageBox{width:100%;text-align: center}
// 小盒子
.lineBox{margin:0;height:120px;
padding: 5px 20px;
// /deep/.el-card__body{
//   padding: 5px 20px;
// }
.el-divider--horizontal{
  margin: 21px 0 0;
}
.lineP{
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  margin-top:5px;
}
.lineB{margin-top:10px;}
}
.lineBox:hover{
  // background: rgba(249, 251, 254, 1);
  background: rgba(0, 0, 0, .05);
}
// 选中
.pitchOn{
  background: #1082ff;
  color: #ffffff;
  span{
    color: #ffffff;
  }
}
.pitchOn:hover{
  background: rgba(16, 116, 225, 1);
  color: #ffffff;
  span{
    color: #ffffff;
  }
}
// 按钮盒子
.buttonBox{
  display: flex;
   justify-content:center;
   .el-button{
     width: 104px;
   }
}
.Putaway {
  background: #737E95;
  position: absolute;
  top: calc((100% - 0.46rem) / 2);
  // right: 5.25rem;
  // right: 340px;
  color: var(--title-text-color);
  display: flex;
  align-items: center;
  font-size: 0.15rem;
  justify-content: center;
  cursor: pointer;
  width: 0.1rem;
  z-index: 8;
  height: 0.56rem;
}
.el-radio-group{
  display: flex;
.el-radio-button{
  flex: 1;
  /deep/.el-radio-button__inner{
    width: 100%;
  }
}
}
</style>
